
import { mapGetters } from 'vuex'
export default {
    name: 'Theme3DesktopSlider',
    data() {
        return {
            slider: 0,
            sliderItemHeight: 60,
            sliders: [1, 2, 3, 4],
            sliderWidth: 733,
            sliderHeight: 250,
        }
    },
    computed: {
        ...mapGetters({
            mobileSlider: 'cms/mobileSlider',
        }),
    },
    methods: {
        getSliderImageWidth() {
            if (process.browser) {
                this.sliderWidth = window.innerWidth
                if (~~this.sliderWidth > 960) {
                    this.sliderWidth = 900
                }
                const height = Math.floor((this.sliderWidth * 450) / 1320)
                this.sliderHeight = height
                return
            }

            const client = process.client

            if (this.globalIsMobileOnly) return
            if (client) {
                // Get slider width when size change
                const val = this.$refs?.refSlider?.clientWidth
                this.sliderWidth = val
                if (val) {
                    // Calculate slider height on ratio 1320:450
                    const height = Math.floor((val * 450) / 1320)
                    this.sliderHeight = height
                    // Display only 4 card if more than that should scrool down
                    this.sliderItemHeight = Math.ceil(height / 4) - 10
                }
            } else {
                setTimeout(() => {
                    this.getSliderImageWidth()
                }, 1000)
            }
        },
    },
}
