import { render, staticRenderFns } from "./MainCard.vue?vue&type=template&id=2a281140&scoped=true"
import script from "./MainCard.vue?vue&type=script&lang=js"
export * from "./MainCard.vue?vue&type=script&lang=js"
import style0 from "./MainCard.vue?vue&type=style&index=0&id=2a281140&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a281140",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3FloatingIcon1: require('/var/app/components/Theme3/FloatingIcon1.vue').default,Theme3DesktopHomeSlider: require('/var/app/components/Theme3/Desktop/HomeSlider.vue').default,Theme3DesktopFeaturedContent1: require('/var/app/components/Theme3/Desktop/FeaturedContent1.vue').default,Theme3DesktopPromotionContent: require('/var/app/components/Theme3/Desktop/PromotionContent.vue').default,Theme3DesktopFeaturedContent2: require('/var/app/components/Theme3/Desktop/FeaturedContent2.vue').default,Theme3Popup: require('/var/app/components/Theme3/Popup.vue').default,Theme3MobileHomeSlider: require('/var/app/components/Theme3/Mobile/HomeSlider.vue').default,Theme3MobileGameMenu: require('/var/app/components/Theme3/Mobile/GameMenu.vue').default})
