
import { mapGetters, mapState } from 'vuex'
export default {
    name: 'Theme3PopupComponent',
    computed: {
        ...mapGetters({
            getCMS: 'cms/getCMS',
            splashScreen: 'cms/splashScreen',
            maintenance: 'theme2/settings/maintenance',
        }),
        ...mapState({
            dialog: (state) => state.settings.displayPopup,
            loading: (state) => state.settings.changeLanguageLoading,
        }),
        isImageExist() {
            let isExist = false
            if (!this.splashScreen?.items) {
                isExist = false

                return false
            }

            this.splashScreen?.items.forEach((item) => {
                const getImage = this.cmsImageLink({
                    path:
                        (item?.image_path && item?.image_path[this.language]) ||
                        '',
                    code: this.splashScreen.component_code,
                })
                if (getImage) isExist = true
            })
            return isExist
        },
    },
    mounted() {},
    methods: {
        closePopup() {
            this.$store.commit('settings/setDisplayPopup', false)
            // Kevin told me to remove the 1 hour timer
            // this.$cookie.set('hidePopup', true, {
            //     path: '/',
            //     maxAge: 60 * 60, // 1 hour
            // })
        },
    },
}
